import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { Breadcrumb, CommandBar, DatePicker, DefaultButton, DetailsListLayoutMode, Dropdown, FontIcon, ICommandBarItemProps, IDropdownOption, Label, Link, Pivot, PivotItem, PrimaryButton, Stack, TextField, TooltipHost, Callout, IDropdownStyles, IconButton, Checkbox } from "@fluentui/react";
import { DOMEApiHelper, deepEqual, isEmpty, onFormatDate } from "../../../../utils/DOMEApiHelper";
import Accordion from "../../../../shared/Accordion/Accordion";
import { useNavigate } from "react-router-dom";
import { GetUserRole } from "../../../../utils/GetUserRole";
import { ScopeDefinitionStyles ,UnAuthorizedStyles} from "./ScopeDefinition.styles";
import ToastMessage from "../../../../shared/ToastMessage/ToastMessage";
import ManagedCatalogAlignment from "../../ManagedCatalogAlignment/ManagedCatalogAlignment";
import ManagedRequirementDefinition from "../../ManagedRequirementDefinition/ManagedRequirementDefinition"; 
export const ScopeDefinition: React.FunctionComponent = () => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [isEditPattern, setIsEditPattern] = useState<boolean>(false);
    const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
    const location = useLocation();
    const [LoadText, setLoadText] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFunctionalScenarioGridLoading, setIsFunctionalScenarioGridLoading] = useState(false);
    const [onChangeDropdown, setOnChangeDropdown] = useState(false);
    const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastTitle, setToastTitle] = useState<string>("");
    const [showToast, setShowToast] = React.useState<boolean>(false);
    const [affectedDataList, setAffectedDataList] = useState<any>([]);   // Pfam , sku
    const [functionalScenarioList, setFunctionalScenarioList] = useState<any>([]);
    const [functionalGridListRef, setFunctionalGridListRef] = useState<any>([]);
    const [reloadSection,setReloadSection] =useState<any>(false);
    const [scopeDefinitionPattern, setScopeDefinitionPattern] = useState<any>([]);
    const [PORId, setPORId] = useState<any>();
    const [catalogCategoryList, setCatalogCategoryList] = useState<any>([]);
    const [userRole, setUserRole] = useState(GetUserRole());
    const [scopeSummaryList, setScopeSummaryList] = useState<any>([]);
    const [showEditGridPanel, setshowEditGridPanel] = useState<boolean>(false);
    const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
    const defaultUserName = sessionStorage.getItem("localAccountName");
    const defaultUserID = sessionStorage.getItem("localAccountId");
    const [porDetails, setPORDetails] = useState<CreatePORModel>(
       { Title: "", StateId: 0,State:"", Capability: 0, Category: 0, ServiceLine: 0, SubCategory: 0, Pattern: 0, UniqueCapabilityCode: 0 ,PrimaryContact: [{ "text": defaultUserName, "objectId": defaultUserID }]});
      const [originalPorDetails, setOriginalPORDetails] = useState<CreatePORModel>(
        { Title: "", StateId: 0,State:"", Capability: 0, Category: 0, ServiceLine: 0, SubCategory: 0, Pattern: 0, UniqueCapabilityCode: 0 ,PrimaryContact: [{ "text": defaultUserName, "objectId": defaultUserID }]});
      
    const [porDetailsRef, setPORDetailsRef] = useState<CreatePORModel>(
        { Title: "", StateId: 0,State:"", Capability: 0, Category: 0, SubCategory: 0, Pattern: 0, UniqueCapabilityCode: 0 ,PrimaryContact: [{ "text": defaultUserName, "objectId": defaultUserID }]});
    const [functionalGridList, setFunctionalGridList] = useState<any>([]);
    const [affectedDataGridList, setAffectedDataGridList] = useState<any>([]);
    const [patternsCombinationList, setPatternsCombinationList] = useState<any>([]);
    const [subCategoryList, setSubCategoryList] = useState<any>([]);
    const [patternsList, setPatternList] = useState<any>([]);
    const [capabilityList, setCapabilityList] = useState<any>([]);
    const [isExpandAccordion, setIsExpandAccordion] = useState(false);
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
    const [isPORLaunchedState, setIsPORLaunchedState] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
    const [contactDetails, setContactDetails] = useState<any>([]);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const [serviceLineList, setServiceLineList] = useState<any>([]);

   
  const {unauthorizedHeader} = UnAuthorizedStyles;
   
    useEffect(() => {
      if (!isEmpty(originalPorDetails) && !isEmpty(originalPorDetails.PrimaryContact)) {
         if(originalPorDetails.IsConfidential && contactDetails.length > 0){  
            const authorizedUser = contactDetails.some((contact : any) => contact.ContactGUID === defaultUserID?.toString());
            if (!authorizedUser) {
              setIsAuthorized(false);
            }
         }
      }
    }, [ originalPorDetails,contactDetails]);

    function uniquearray(arr: any) {
        return arr.filter((obj: any, index: any, self: any) => index === self.findIndex((o: any) => o["key"] === obj["key"]));
    }

    const reloadRD = () => {
       
      setReloadSection(true);

      setTimeout(() => {
        setReloadSection(false);
      
    }, 1000);

    }
    const getFSAndDataTypesMetaData = (id: any,isreqFromEditPattern:any) => {
        if(!isreqFromEditPattern){
            setFunctionalGridList([]);       
        }
        setIsFunctionalScenarioGridLoading(true);
        const requestCapabilityOptions = {
            method: 'Get',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors'
        };
        DOMEApiHelper('PORDetails/GetFunctionalScenarios?uniqueCapabilityCode=' + id, requestCapabilityOptions)
            .then(res => {
                if (res.length === 0) {
                    return false;
                }
                let functionalScenaroList = res.filter((data: any) => data.MetadataType.includes("FunctionalScenario")).map((data: any) => ({
                    key: data.Id,
                    text: data.Title,
                }));
                setFunctionalScenarioList(functionalScenaroList);
                if(!isreqFromEditPattern){
                    let functionalGridList:any=[];
                    functionalScenaroList.forEach((element: any) => {
                        functionalGridList.push(
                            {
                                FunctionalScenarioId: element.key,
                                FunctionalScenario: element.text,
                                AffectedData: [],
                                InScope: false,
                                UniqueCapabilityCode:id,
                                PORId:PORId
                            });
                    });
                    setFunctionalGridList([...functionalGridList]);
                    setIsFunctionalScenarioGridLoading(false);
                }
                else{
                    getPatternDetailsByPORIdandUCC(PORId, id);
                }
                let affectedDataList = res.filter((data: any) => data.MetadataType.includes("DataType")).map((data: any) => ({
                    key: data.Id,
                    text: data.Title,
                }));
                setAffectedDataList(affectedDataList);

            }).catch(error => {
                setIsFunctionalScenarioGridLoading(false);
            });
    }
    const getPatternDetailsByPORIdandUCC= (PORId:any ,uniqueCapabilityCode:any)=>{
        const requestOptions = {
            method: 'Get',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors'
        };

        DOMEApiHelper('PORDetails/GetPORPatternDetails?PorId=' + PORId + '&UniquecapabilityCode='+uniqueCapabilityCode, requestOptions)
            .then(res => {
              if(!isEmpty(res)){

                let functionalGridList:any=[];
               
              res[0]["CatalogDataList"]= res[0]["CatalogDataList"].sort((a:any, b:any) => a.FunctionalScenarioId - b.FunctionalScenarioId);
             
                res[0]["CatalogDataList"].forEach((element: any) => {
                    let getSavedAffectedData=res[0]["AffectedDataPatternList"].filter((x:any)=>x.FunctionalScenarioId==element.FunctionalScenarioId);
                    let affectedData= getSavedAffectedData.map((item: any) => {
                        return {
                            FunctionalScenarioId: item.FunctionalScenarioId,
                            InScope: item.InScope,
                            AffectedDataTypeId: item.AffectedDataTypeId,
                            AffectedDataTypeName: item.AffectedDataType,
                            AffectedDataValue: item.AffectedDataValue,
                            PORId:PORId,  // from api Fetching PORId and UniqueCapabilityCode
                            UniqueCapabilityCode:uniqueCapabilityCode,
                            IsDeleted:false, // bydefault add is delete as false 
                            IsLocked: item.IsLocked
                        }
                    });
                    functionalGridList.push(
                        {
                            FunctionalScenarioId: element.FunctionalScenarioId,
                            FunctionalScenario: element.FunctionalScenarioName,
                            AffectedData: affectedData,
                            InScope: element.InScope,
                            Volume: !isEmpty(element.Volume)?parseInt(element.Volume):null,
                            PORId: PORId,
                            UniqueCapabilityCode: uniqueCapabilityCode
                        });

                });
                functionalGridList.forEach((functionalscenario:any) => {
                    functionalscenario.AffectedData.forEach((element:any) => {
                        functionalscenario[element.AffectedDataTypeId+element.AffectedDataValue]=element.InScope;
                        functionalscenario[element.AffectedDataTypeId + element.AffectedDataValue + 'IsLocked'] = element.IsLocked;
                    })
                });

                setFunctionalGridList([...functionalGridList]);
                setFunctionalGridListRef(JSON.parse(JSON.stringify(functionalGridList)));  
                createAffectedDataList(functionalGridList);
              }
                setIsFunctionalScenarioGridLoading(false);
            }).catch(res => {
                setToastMessage(res.toString());
                setToastType(CoherenceNotificationType.ERROR);
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
            });

    }


    const checkIsLocked = (selectedObj: any) => {
        // If any AffectedData is locked then return true
        if (selectedObj.AffectedData.filter((x: any) => x.IsLocked == true).length > 0) {
            return true;
        }
        return false;
    }


  const LoadPatternEditMode = (id : any , item :any)=>{ 
    const requestOptions = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    DOMEApiHelper('PORDetails/GetPatternDetail?Id=' + id, requestOptions)
      .then(res => {
      if(res.length===0){
          return false;
        }
        setPatternsCombinationList(res);
        //category total list 
        let catalogCategoryList = (res).map((item: any) => {
          return {
            key: item.CatalogCategoryId,
            text: item.CatelogCategoryTitle,
            IsPatternRequired: item.IsPatternRequired
          }
        })
        let uniqueCatalogCategoryList = uniquearray(catalogCategoryList);
        setCatalogCategoryList([...uniqueCatalogCategoryList]);

        //  Subcategory total list
     
        let filterSubCategoryFulllist = res.filter((x: any) => x.CatalogCategoryId === item.CatalogCategoryId);
        
        let subCategoryFullList = (filterSubCategoryFulllist).map((item: any) => {
          return {
            key: item.SubCategoryId,
            text: item.SubCategoryTitle
          }
        })
        let uniqueSubCategoryFullList = uniquearray(subCategoryFullList);
        setSubCategoryList([...uniqueSubCategoryFullList]);
        
        //  pattern total list
        
        let filterPatternFulllist = res.filter((x: any) => x.SubCategoryId === item.SubCategoryId && x.CatalogCategoryId===item.CatalogCategoryId);
        let patternFullList = (filterPatternFulllist).map((item: any) => {
          return {
            key: item.PatternId,
            text: item.PatternTitle
          }
        })
        let uniquePatternFullList = uniquearray(patternFullList);
        setPatternList([...uniquePatternFullList]);
        //  Capability total list
        
        let filterCapabilityFulllist = res.filter((x: any) =>  x.SubCategoryId === item.SubCategoryId && x.CatalogCategoryId===item.CatalogCategoryId && x.PatternId === item.PatternId);
        let capabilityFullList = (filterCapabilityFulllist).map((item: any) => {
          return {
            key: item.CapabilityId,
            text: item.CapabilityTitle
          }
        })
        let uniqueCapabilityFullList = uniquearray(capabilityFullList);
        
      
        setCapabilityList([...uniqueCapabilityFullList]);
 
       getFSAndDataTypesMetaData(porDetails.UniqueCapabilityCode,true); 
        
      }).catch(error => {
        // setIsLoaded(false); 
      });
  }

  //all pattern selection dropdown code start
  const [editRowColumns, seteditRowColumns] = useState<any>(
    [
      { "isEditable": true, "type": "DropDown", "label": "CatalogCategory", "key": "CatalogCategoryId" },
      { "isEditable": true, "type": "DropDown", "label": "Subcategory", "key": "SubCategoryId" },
      { "isEditable": true, "type": "DropDown", "label": "Pattern", "key": "PatternId" },
      { "isEditable": true, "type": "DropDown", "label": "Capability", "key": "CapabilityId" },
      { "isEditable": true, "type": "DropDown", "label": "AffectedDataTypes", "key": "AffectedDataTypes" },
      { "isEditable": true, "type": "TextArea", "label": "Volume", "key": "Volume" },
    ]
  );
  const checkPattern=(capability:any)=>{
    let status = false;
    scopeSummaryList.forEach((item:any) => {
      if(item.CatalogCategoryId === porDetails.Category && item.SubCategoryId === porDetails.SubCategory &&
        item.PatternId === porDetails.Pattern && item.CapabilityId === capability){
          setPORDetails({ ...porDetails, Capability: -1 });   
          setTimeout(() => {
            setPORDetails({ ...porDetails, Capability: 0});                           
          }, 100); 
          setAffectedDataGridList([]);
          setFunctionalGridList([]);
          setToastTitle("Pattern");
          setToastMessage("Pattern combination already exists. Please select different pattern");
          setToastType(CoherenceNotificationType.NONE);
          setShowToast(true);
          setTimeout(() => {
            setIsDisabledSaveButton(false); 
            setShowToast(false);           
          }, 5000);        
          status=true
        }          
      });  
     return status;   
  }
  
  // Category
  const onChangeCategory = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
    setOnChangeDropdown(true);
    porDetails.Category = item.key;
    porDetails.Pattern = 0;
    porDetails.Capability = 0;
    porDetails.UniqueCapabilityCode = 0;
    setPORDetails(porDetails);
    setSubCategoryList([]);
    setPatternList([]);
    setCapabilityList([]);
    setIsDisabledSaveButton(false); 

    let getSubCategoryByCategory = patternsCombinationList.filter((x: any) => x.CatalogCategoryId === item.key);
    let SubCategoryListFiltered = (getSubCategoryByCategory).map((item: any) => {
      return {
        key: item.SubCategoryId,
        text: item.SubCategoryTitle
      }
    })
    let uniqueSubCategoryList = uniquearray(SubCategoryListFiltered)
    setSubCategoryList([...uniqueSubCategoryList]);

    //if subcategory has only one option as n/a then select bydefault and populate paterns dropdown 
    if ((uniqueSubCategoryList.length === 1 && uniqueSubCategoryList.filter((x: any) => x.text === "n/a").length > 0) || uniqueSubCategoryList.length === 1) {          
        porDetails.SubCategory = uniqueSubCategoryList[0].key;
        setPORDetails(porDetails);
        onChangeSubCategory(event,uniqueSubCategoryList[0]);
      }
      else {
        porDetails.SubCategory = 0
        setPORDetails(porDetails);
        
      }
      };
      // Subcategory 
      const onChangeSubCategory = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined, index?: any): void => { 
        setOnChangeDropdown(true);
        let getPatternsBySubCategory = patternsCombinationList.filter((x: any) => (x.CatalogCategoryId=== porDetails.Category && x.SubCategoryId === item?.key));
        let patternsListFiltered = (getPatternsBySubCategory).map((item: any) => {
          return {
            key: item.PatternId,
            text: item.PatternTitle
          }
        })
        let uniquePatternList = uniquearray(patternsListFiltered)
        setPatternList([...uniquePatternList]);
        porDetails.SubCategory = item?.key;
        if(uniquePatternList.length===1){       
          porDetails.Pattern =uniquePatternList[0].key;  
          setPORDetails(porDetails); 
          onChangePattern(event,uniquePatternList[0]);
        }
        else{
        porDetails.Capability = 0;  
        porDetails.UniqueCapabilityCode = 0; 
        setCapabilityList([]);
        }    
        setPORDetails(porDetails); 
  };
  // Pattern
  const onChangePattern = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => { 
    setOnChangeDropdown(true);
    let getCapabilitysByPattern = patternsCombinationList.filter((x: any) => ( x.CatalogCategoryId=== porDetails.Category && x.SubCategoryId === porDetails.SubCategory && x.PatternId === item?.key));
    let capabilityListFiltered = (getCapabilitysByPattern).map((item: any) => {
      return {
        key: item.CapabilityId,
        text: item.CapabilityTitle
      }
    })
    let uniqueCapabilityList = uniquearray(capabilityListFiltered)
    setCapabilityList([...uniqueCapabilityList]);
    porDetails.Pattern = item.key; 
    if(uniqueCapabilityList.length===1 && !checkPattern(uniqueCapabilityList[0].key) ){
      porDetails.Capability =uniqueCapabilityList[0].key;
      setPORDetails(porDetails);
      onChangeCapability(event,uniqueCapabilityList[0]);
    }    
    else{
      porDetails.Capability=0;       
      }
    setPORDetails(porDetails); 
  };
  // Capability
  const onChangeCapability = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => { 
    
    if(!checkPattern(item.key)){
        setOnChangeDropdown(true);
        porDetails.Capability = item.key;
        let uniquecapabilityCode = patternsCombinationList.filter((item: any) => 
        item.CatalogCategoryId === porDetails.Category &&
        item.SubCategoryId === porDetails.SubCategory &&
        item.PatternId === porDetails.Pattern &&
        item.CapabilityId === porDetails.Capability
        ).map((item: any) => item.UniqueCapabilityCode);
      let id = [...uniquecapabilityCode][0];
      porDetails.UniqueCapabilityCode=id;
      setPORDetails(porDetails);               
      getFSAndDataTypesMetaData(id,false);  
    }  
   };
  interface CreatePORModel {
    Title: string;
    StateId?: number;
    ServiceLine?: number;
    Category?: number;
    SubCategory: any;
    Pattern?: number;
    Capability?: number;
    Attachments?: string | null;
    CreatedBy?: string | null;
    UniqueCapabilityCode?: number;
    State?:string;
    PrimaryContact?: any;
    IsConfidential?:boolean;
  } 

  const LoadContactData = (PORID: string) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    setIsLoaded(true);
    DOMEApiHelper('PORDetails/GetContactDetailsByPORId?PORId=' + PORID, requestOptions)
      .then(res => {
        setIsLoaded(false);
        setContactDetails([
          ...res.map((item: any) => ({
            Id: item.Id,
            ContactTypeId: item.ContactType,
            itemSelected: false,
            ContactGUID: item.ContactGUID,
            ContactUserName: item.ContactGUIDUserName,
            ContactType: item.ContactTypeName

          }))
        ]);
      }).catch(res => {
        setIsLoaded(false);
      });
  }
  const LoadPORData = (PORID: string) => {
    setPORDetails(porDetails);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    }; 
    DOMEApiHelper('PORDetails/GetPORDetailsByID?id=' + PORID, requestOptions)
      .then(res => { 
        if (!isEmpty(res)) {         
          setPORDetails(res[0]);
          const updatedPOR = {
            ...porDetails,        
             StateId: res[0].StateId,
             State: res[0].State,
            PrimaryContact: isEmpty(res[0].PrimaryContactUserName) ? [] : [{ "text": res[0].PrimaryContactUserName, "objectId": res[0].PrimaryContact }], 
            IsConfidential:res[0].IsConfidential,
            ServiceLine: res[0].ServiceLine.split(';')[0]
          };
          setPORDetails(updatedPOR); 
          setOriginalPORDetails(updatedPOR);  
          setIsPageLoaded(true);               
          checkPORState(res[0].StateId,res[0].State);        
        }
       
      }).catch(res => {
        setIsLoaded(false);
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
  };

  const LoadPatternsbyServiceLine = (id: any) => {
   
    const requestOptions = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
 
    DOMEApiHelper('PORDetails/GetPatternDetail?Id=' + id, requestOptions)
      .then(res => {
      
        if(res.length===0){
 
          return false;
        }
 
        setPatternsCombinationList(res);
        let catalogCategoryList = (res).map((item: any) => {
          return {
            key: item.CatalogCategoryId,
            text: item.CatelogCategoryTitle,
            IsPatternRequired: item.IsPatternRequired
          }
        })
        let uniqueCatalogCategoryList = uniquearray(catalogCategoryList);
        setCatalogCategoryList([...uniqueCatalogCategoryList]);
 
        //if category has only one option 
        if (uniqueCatalogCategoryList.length === 1) {  
          porDetails.Category = uniqueCatalogCategoryList[0].key;
          setPORDetails(porDetails);
          let getSubCategoryByCategory = res.filter((x: any) => x.CatalogCategoryId === uniqueCatalogCategoryList[0].key);
          let subCategoryListFiltered = (getSubCategoryByCategory).map((item: any) => {
            return {
              key: item.SubCategoryId,
              text: item.SubCategoryTitle
            }
          })
          let uniqueSubCategoryList= uniquearray(subCategoryListFiltered);
          setSubCategoryList(uniqueSubCategoryList);
          porDetails.SubCategory = 0;
          porDetails.Pattern = 0;
          porDetails.Capability = 0;
          setPORDetails(porDetails);
          //if subcategory has only one option as n/a then select bydefault and populate paterns dropdown 
          if ((uniqueSubCategoryList.length === 1 && uniqueSubCategoryList.filter((x: any) => x.text === "n/a").length > 0) || uniqueSubCategoryList.length === 1) {          
            porDetails.SubCategory = uniqueSubCategoryList[0].key;
            setPORDetails(porDetails);
            let getPatternsBySubCategory = res.filter((x: any) => x.SubCategoryId === uniqueSubCategoryList[0].key);
            let patternsListFiltered = (getPatternsBySubCategory).map((item: any) => {
              return {
                key: item.PatternId,
                text: item.PatternTitle
              }
            })
            let uniquePatternsList= uniquearray(patternsListFiltered);
            setPatternList(uniquePatternsList);
            porDetails.Pattern = 0;
            porDetails.Capability = 0;
            setPORDetails(porDetails);
              //select pattern if only one pattern is there
              if(uniquePatternsList.length==1){
                let getCapabilitysByPattern = res.filter((x: any) => x.PatternId === uniquePatternsList[0].key);
                let capabilityListFiltered = (getCapabilitysByPattern).map((item: any) => {
                  return {
                    key: item.CapabilityId,
                    text: item.CapabilityTitle
                  }
                })
                let uniqueCapabilityList = uniquearray(capabilityListFiltered)
                setCapabilityList([...uniqueCapabilityList]);
                porDetails.Pattern = uniquePatternsList[0].key; 
                setPORDetails(porDetails);  
              //select capability if only one capability is there
                if(uniqueCapabilityList.length===1){
                  porDetails.Capability=uniqueCapabilityList[0].key;
                  setPORDetails(porDetails);
                }    
                else{
                  porDetails.Capability=0;       
                  }
                setPORDetails(porDetails);  
              }
          }
          else {
            porDetails.SubCategory = 0
            setPORDetails(porDetails);
            
          }
        }
        else {
          porDetails.Category = 0
          setPORDetails(porDetails);
          
        }
 
      }).catch(error => {
        // setIsLoaded(false); 
      });
    };

  const requestOptionsGETALL = {
    method: 'GET'
  };
  const LoadCatlogCategoryDetails = () => { 
    DOMEApiHelper('PORDetails/GetAllPORDetailsDataAsync', requestOptionsGETALL)
      .then(res => {
        if (!isEmpty(res)) {
          setServiceLineList([
            ...res[0]["ServiceLineList"].map((item: any) => ({
              key: item.Id,
              text: item.Title,
            }))
          ]) 
        }
      }).catch(error => { 
        setToastTitle("Error while fetching catalog alignment data  ");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });
  } 
  const checkPORState = (StateId: number,State:string) => {

    // for States={'cancelled', 'duplicate','launched'}
    if (StateId === 1 || StateId === 3 || StateId === 5) {
      setIsPORLaunchedState(true);
      setIsDisabledSaveButton(true);
      setToastType(CoherenceNotificationType.STANDARD);
      setToastMessage("Catalog Alignment is not editable for '"+ State + "' POR's");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 10000);
    }
    else{
      setIsDisabledSaveButton(false);
      setIsPORLaunchedState(false);
    }
  }
  const checkPatternValidate=()=>{
    var createdByID = sessionStorage.getItem("localAccountId");
    let scopeDefinitionPattern = functionalGridList.map((item: any) => {
      return {
        PORId: item.PORId,
        FunctionalScenarioId: item.FunctionalScenarioId,
        UniqueCapabilityCode: item.UniqueCapabilityCode,
        InScope: item.InScope,
        Volume:isEmpty(item.Volume) ? 0:item.Volume,
        CreatedBy: createdByID,
        AffectedData:item.AffectedData.filter((x:any)=>x.IsDeleted==false).map((obj:any)=>{ 
            return {
              PORId: obj.PORId,
              FunctionalScenarioId: obj.FunctionalScenarioId,
              UniqueCapabilityCode: obj.UniqueCapabilityCode,
              AffectedDataTypeId:obj.AffectedDataTypeId,
              AffectedDataValue:obj.AffectedDataValue,
              InScope: obj.InScope,
              CreatedBy: createdByID,
              IsDeleted:false   
           };  
        })   
      }     
    });
   
    const requestValidateOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(scopeDefinitionPattern),
      mode: 'cors'
  };
      DOMEApiHelper('PORDetails/ValidatePattern', requestValidateOptions)
      .then(res => {   
        if(!isEmpty(res) && !isEmpty(res.Message)){ 
          setToastTitle("Data Value Already Exists");
          setToastMessage(res.Message);
          setToastType(CoherenceNotificationType.NONE);
          setShowToast(true);
          setTimeout(() => {
            setIsDisabledSaveButton(false);
            setShowToast(false);
          }, 10000);      
          return;
        }
        setTimeout(() => {
          setIsExpandAccordion(false);    
        }, 100);                       
        if(isEditPattern){
          if(porDetailsRef.UniqueCapabilityCode!=porDetails.UniqueCapabilityCode){
            DeletePattern(scopeDefinitionPattern); 
          }else{
            editPattern(); 
          }
        }
        else{
          createPattern(scopeDefinitionPattern);
        }
      }).catch(res => {
        setToastMessage(res.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });
        
  }
const onSavePatternClick=()=>{
  setIsDisabledSaveButton(true);
    checkPatternValidate() ;
}
  //create pattern
  const createPattern= (scopeDefinitionPattern:any) => {    
    var createdByID = sessionStorage.getItem("localAccountId");
   
    let savePatternModel={"ScopeDefinitionPattern":scopeDefinitionPattern}  
    const requestUpdateOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(savePatternModel),
      mode: 'cors'
  };
  setToastTitle("Scope Summary");
  setToastMessage("New Pattern is being added.");
  setToastType(CoherenceNotificationType.PENDING);
  setShowToast(true);
   DOMEApiHelper('PORDetails/AddPattern', requestUpdateOptions)
     .then(res => { 
       setToastType(CoherenceNotificationType.SUCCESS);  
       setToastMessage("Pattern has been saved successfully."); 
       setTimeout(() => {
        setShowToast(false); 
      }, 5000);
      LoadData(PORId);
      handleOnPanelXClose();      
     
     }).catch(res => {
      setIsExpandAccordion(true); 
      setIsDisabledSaveButton(false);
       setToastMessage(res.toString());
       setToastType(CoherenceNotificationType.ERROR);
       setTimeout(() => {
         setShowToast(false);
       }, 5000);
     });
 
  }
  const DeletePattern =(scopeDefinitionPattern:any)=>{ 

    var modifiedBy = sessionStorage.getItem("localAccountId");
    let todeleteuniquecapabilitycoderecords = [];
   
    if(!isEditPattern){
       todeleteuniquecapabilitycoderecords = bulkEditSelectedData.filter((item:any) => item.itemSelected === true).map((item: any) =>item.UniqueCapabilityCode.toString() ); 
                    
    }
    else{  
      todeleteuniquecapabilitycoderecords.push(porDetailsRef.UniqueCapabilityCode?.toString());
    }
    let deleteeditedData = {
      PORId:PORId,
      UniqueCapabilityCodes:todeleteuniquecapabilitycoderecords,
      ModifiedBy:modifiedBy
      };

      
    const requestUpdateOptions = {
      method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(deleteeditedData),
        mode: 'cors'
  };
  if(!isEditPattern){
  setToastTitle("Delete pattern(s)");
  setToastMessage("Deleting the selected pattern(s)");
  setToastType(CoherenceNotificationType.PENDING);
  setShowToast(true);
  }
   DOMEApiHelper('PORDetails/DeletePattern', requestUpdateOptions)
     .then(res => {
       if(!isEditPattern){
         setIsSelectedAll(false); 
         setToastType(CoherenceNotificationType.SUCCESS);
         setToastMessage("Pattern(s) have been successfully deleted.");
         setTimeout(() => {
          setShowToast(false);
        }, 3000);
       
        setBulkEditSelectedData([]); 
        setIsDisabledSaveButton(false);
 
        if (res.Message==="Success") {
          LoadData(PORId);       
        }
       }
       else{        
         createPattern(scopeDefinitionPattern);
       } 
     }).catch(res => {
      setIsExpandAccordion(true); 
      setIsDisabledSaveButton(false);
       setToastMessage(res.toString());
       setToastType(CoherenceNotificationType.ERROR);
       setTimeout(() => {
         setShowToast(false);
       }, 3000);
     });
 
  }
  
  const editPattern= () => {    
    var modifiedBy = sessionStorage.getItem("localAccountId");
    let getModifiedData = functionalGridList.filter((item:any) => {
      // Find if the current item exists in functionalGridListRef using the custom comparison function
      return !functionalGridListRef.some((refItem:any) =>
      deepEqual(refItem, item)
      );
    });    

    if(getModifiedData.length==0){
      return;
    }
    let scopeDefinitionPattern = getModifiedData.map((item: any) => {
      return {
        PORId: item.PORId,
        FunctionalScenarioId: item.FunctionalScenarioId,
        UniqueCapabilityCode: item.UniqueCapabilityCode,
        InScope: item.InScope,
        Volume:isEmpty(item.Volume)?null: item.Volume,
        ModifiedBy: modifiedBy,
        AffectedData:item.AffectedData.map((obj:any)=>{
          return {
                  PORId: obj.PORId,
                  FunctionalScenarioId: obj.FunctionalScenarioId,
                  UniqueCapabilityCode: obj.UniqueCapabilityCode,
                  AffectedDataTypeId:obj.AffectedDataTypeId,
                  AffectedDataValue:obj.AffectedDataValue,
                  InScope: obj.InScope,
                  ModifiedBy: modifiedBy,
                  IsDeleted:obj.IsDeleted    
          };
        })   
      }     
    });   

    let savePatternModel={"ScopeDefinitionPattern":scopeDefinitionPattern}
    const requestUpdateOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(savePatternModel),
      mode: 'cors'
  };
  setToastTitle("Scope Summary");
  setToastMessage("Update Pattern is in progress.");
  setToastType(CoherenceNotificationType.PENDING);
  setShowToast(true);
 
   DOMEApiHelper('PORDetails/UpdatePattern', requestUpdateOptions)
     .then(res => { 
       setToastType(CoherenceNotificationType.SUCCESS);  
       setToastMessage("Pattern has been updated successfully."); 
        setTimeout(() => {
        setShowToast(false); 
        }, 5000);
        LoadData(PORId);
        handleOnPanelXClose();
     
     }).catch(res => { 
       setIsExpandAccordion(true);  
       setIsDisabledSaveButton(false);
       setToastMessage(res.toString());
       setToastType(CoherenceNotificationType.ERROR);
       setTimeout(() => {
         setShowToast(false);
       }, 5000);
     });
 
  }
  //all pattern selection dropdown code end
  const validateChangesBeforeClose = () => {
     if (isEditPattern) {
    const modifiedData = functionalGridList.filter((item:any) => {
      // Find if the current item exists in functionalGridListRef using the custom comparison function
      return !functionalGridListRef.some((refItem:any) =>
      deepEqual(refItem, item)
      );
    }); 

    if (modifiedData.length > 0 ) {
      const isConfirmed = window.confirm('Your unsaved edits will be discarded, do you want to proceed?');
      if (!isConfirmed) {
        return;
      }
    }
  } 
  handleOnPanelXClose();
  } 
  const handleOnPanelXClose = () => {
    // If isEditPattern is false or if isConfirmed is true
    setIsEditPattern(false);
    setshowEditGridPanel(false);
    setOnChangeDropdown(false);
    seteditColumnsDefaultValues([]);
    setAffectedDataList([]);
    setFunctionalGridList([]);
    setAffectedDataGridList([]);
    setBulkEditSelectedData([]);
    setIsDisabledSaveButton(false); 
    
    setPORDetails({ ...porDetails,Capability: 0, Category: 0, SubCategory: 0, Pattern: 0, UniqueCapabilityCode: 0 });
    
  }

  const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]);
        //  All check box
        const handleSelectAllCheckbox= (event:any) => { 
          setIsSelectedAll(event.target.checked); 
          scopeSummaryList.forEach((x:any)=>{
          x.itemSelected = event.target.checked ;
          })
            setScopeSummaryList([...scopeSummaryList]);
            const updateBulkUpdateData = event.target.checked ? scopeSummaryList.filter((x: any) => x.itemSelected === true) : [];
            setBulkEditSelectedData([...updateBulkUpdateData]) 
       };
    // Single Selection Box
    const handleSelectionCheckbox = (event:any,item:any) => {
      if (event.target.checked) {
      item.itemSelected =true;
     setBulkEditSelectedData([...bulkEditSelectedData, item]);
      
      } else {
        if (isSelectedAll)
          setIsSelectedAll(false);
        item.itemSelected =false;
        setBulkEditSelectedData(
        bulkEditSelectedData.filter((selectedItem: any) => selectedItem.UniqueCapabilityCode !== item.UniqueCapabilityCode)
        );
        
      }   
      
      scopeSummaryList.forEach((element:any) => {
          if(element.UniqueCapabilityCode===item.UniqueCapabilityCode){
              element.itemSelected=item.itemSelected
          }
      });  
      setScopeSummaryList([...scopeSummaryList]);   
    };
  useEffect(() => {
    const routerName = location.pathname.split('/')[1];
    if (routerName === 'ScopeDefinition') {
      setIsExpandAccordion(true);
      const idStartIndex = window.location.href.lastIndexOf('/');
      const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null; 
      if (id) {  
        LoadContactData(id); // for Contact list to Authorize the user
        LoadData(id); // Scope summary data  
        LoadPORData(id);// for State
        LoadCatlogCategoryDetails(); // for Catalog category list
        setIsEditMode(true); 
        setPORId(parseInt(id));
      }
    }
  }, [location]); 
  const LoadData = (id: any) => {
    setIsLoaded(true);
    setIsExpandAccordion(true);
    const requestOptions = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    DOMEApiHelper('PORDetails/GetSummaryScopeDetailByID?Id=' + id, requestOptions)
      .then(res => {
        setScopeSummaryList([
          ...res.map((item: any) => ({
            CatalogCategoryId: item.CatalogCategoryId,
            CatalogCategory:item.CatalogCategory,
            ServiceLineId:item.ServiceLineId,
            ServiceLine:item.ServiceLine,
            SubCategoryId: item.SubCategoryId,
            SubCategory:item.SubCategory,
            PatternId:item.PatternId,
            Pattern:item.Pattern,
            CapabilityId: item.CapabilityId,
            Capability : item.Capability,
            UniqueCapabilityCode:item.UniqueCapabilityCode,
            itemSelected:false,
            AffectedDataTypes:item.AffectedDataTypes,
            AffectedDataValues: item.AffectedDataValues,
           
          })) 
        ]);  
      
        setIsLoaded(false);
      }).catch(error => {
        setIsLoaded(false);
      });
  }
  let navigate = useNavigate();
  const getColumns = (): CoherenceDataGridColumn<any>[] => {
    var advancedColumns: CoherenceDataGridColumn<any>[] = [
      {
        key: 'selectAll',
        name: 'Select All',
        fieldName: 'SelectAll',
        type: 'string',
        isResizable: false,
        minColumnWidth: 20,
        maxWidth: 20,
        ariaLabel: 'Select All',
        data: [],
        onRender: (item: any) => {
            return <Checkbox ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)}  checked={item.itemSelected} />;
        },
        onRenderHeader: (item: any) => {
            return <Checkbox ariaLabel="Select all rows" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleSelectAllCheckbox(event)} checked={isSelectedAll} />;
        },
      },
      {
        key: 'Actions',
        name: 'Edit',
        type: 'actions',
        menuActions: [],
        isResizable: false,
        minColumnWidth: 50,
        maxWidth: 50,
        data: [],
        visibleActions: [
          {
            onClick: (item) => {
              setIsPatternChangedInEditMode(false);
              setBooleanAffectedDataValueErrorMessage(false); 
              setShowAffectedDataValueErrorMessage(false);
              seteditColumnsDefaultValues(item);
              setshowEditGridPanel(true); 
              setIsEditPattern(true);  
              setAffectedDataGridList([]);
              setFunctionalGridList([]);
              setSubCategoryList([]);
              setPatternList([]);
              setCapabilityList([]);
              setAffectedDataList([]);
              setFunctionalScenarioList([]);
              setAffectedDataObj({ ...affectedDataObj,AffectedDataTypeId: "", AffectedDataTypeName: "", AffectedDataValue: "", FunctionalScenarioIds: [] });
              porDetails.Capability= item.CapabilityId;
              porDetails.Category= item.CatalogCategoryId;
              porDetails.SubCategory=  item.SubCategoryId;
              porDetails.Pattern= item.PatternId;
              porDetails.UniqueCapabilityCode= item.UniqueCapabilityCode;
              setPORDetails(porDetails);         
              setPORDetailsRef(JSON.parse(JSON.stringify(porDetails)));    
              let serviceLineId = serviceLineList.filter((x: any) => x.text === porDetails.ServiceLine)[0].key;
              LoadPatternEditMode(serviceLineId, item);
            },
            key: 'edit',
            text: 'Action',
            ariaLabel: 'action',
            iconProps: { iconName: 'Edit', style: { color: "#0078d4" } }
          }
        ]
      },
      {
        key: 'CatalogCategory',
        name: 'Catalog Category',
        fieldName: 'CatalogCategory',
        type: 'string',
        isResizable: true,
        minColumnWidth: 140,
        maxWidth: 140,
        ariaLabel: 'Catalog Category',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.CatalogCategory}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.CatalogCategory}</span>
          </TooltipHost>
        },
        iconClassName: "customSortIcon"
      },
      {
        key: 'SubCategory',
        name: 'SubCategory',
        fieldName: 'SubCategory',
        type: 'string',
        isResizable: true,
        minColumnWidth: 140,
        maxWidth: 140,
        ariaLabel: 'SubCategory',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.SubCategory}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.SubCategory}</span>
          </TooltipHost>
        },
        iconClassName: "customSortIcon"
      },
      {
        key: 'Pattern',
        name: 'Pattern',
        fieldName: 'Pattern',
        type: 'string',
        isResizable: true,
        minColumnWidth: 200,
        maxWidth: 200,
        ariaLabel: 'Pattern',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.Pattern}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.Pattern}</span>
          </TooltipHost>
        },
        iconClassName: "customSortIcon"
      },
      {
        key: 'Capability',
        name: 'Capability',
        fieldName: 'Capability',
        type: 'string',
        isResizable: true,
        minColumnWidth: 200,
        maxWidth: 200,
        ariaLabel: 'Capability',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.Capability}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.Capability}</span>
          </TooltipHost>
        },
        iconClassName: "customSortIcon"
      },
      {
        key: 'Data Type(s)',
        name: 'Data Type(s)',
        fieldName: 'Data Type',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        isMultiline:true, 
        ariaLabel: 'Data Type',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.AffectedDataTypes}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.AffectedDataTypes}</span>
          </TooltipHost>
        },
        iconClassName: "customSortIcon"
      },
      {
        key: 'Data Value(s)',
        name: 'Data Value(s)',
        fieldName: 'Data Value',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 220,
        isMultiline:true, 
        ariaLabel: 'Data Value(s)',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.AffectedDataValues}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.AffectedDataValues}</span>
          </TooltipHost>
        },
        iconClassName: "customSortIcon"
      }]; 
    return advancedColumns;
  };
  //pattern data types grid code start  
const getCheckedData = (selectedObj: any, affectedDataObj: any) => {
  let findCheckedData=selectedObj.AffectedData.filter((x:any)=>x.AffectedDataTypeId===affectedDataObj.AffectedDataTypeId && x.AffectedDataTypeName===affectedDataObj.AffectedDataTypeId);
  if(findCheckedData.length>0){
    return affectedDataObj[0].InScope 
  }
   return false;
  }
  //Function Scenario Grid Code Start
  const getFunctionalScenarioColumns = (): CoherenceDataGridColumn<any>[] => {
    var advancedColumns: CoherenceDataGridColumn<any>[] = [
      {
        key: 'FunctionalScenario',
        name: 'Functional Scenario',
        fieldName: 'FunctionalScenario',
        type: 'string',
        isResizable: true,
        minColumnWidth: 200,
        maxWidth: 220,
        ariaLabel: 'Data Type',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.FunctionalScenario}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.FunctionalScenario}</span>
          </TooltipHost>
        },
        iconClassName: "customSortIcon"
      },
      {
        key: 'InScope',
        name: 'InScope',
        fieldName: 'InScope',
        type: 'boolean',
        isResizable: true,
        ariaLabel: 'InScope',
        minColumnWidth: 110,
        maxWidth: 110,
        onRender: (item: any) => {
          return <Checkbox ariaLabel="Select In Scope" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleInScopeChange(event, item)} disabled={checkIsLocked(item)}checked={item.InScope} />
        },
        data: ["Managed", "Accessibility"]
      },
      {
        key: 'Volume',
        name: 'Volume',
        fieldName: 'Volume',
        type: 'string',
        isResizable: false,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'InScope',
        data: ["Managed", "Accessibility"],
        onRender: (item: any) => {
          return <TextField
            type="number"
            min={0}
            placeholder={"Enter Volume"}
            value={item.Volume}
            onChange={(event) => onChangeVolumeValueFSGrid(event, item)}
          />
        }
      }];
    if (functionalGridList != undefined && functionalGridList.length > 0) {
      functionalGridList[0].AffectedData.forEach((AffectedDataObj: any, i: any) => {
        if(!AffectedDataObj.IsDeleted){  
        advancedColumns.push({
          key: i + AffectedDataObj.AffectedDataTypeId + AffectedDataObj.AffectedDataValue,
          name: AffectedDataObj.AffectedDataTypeName + ' : ' + AffectedDataObj.AffectedDataValue,
          fieldName: AffectedDataObj.AffectedDataTypeName + ' ' + AffectedDataObj.AffectedDataValue,
          type: 'string',
          isResizable: true,
          minColumnWidth: 200,
          maxWidth: 220,
          ariaLabel: 'Data Type',
          data: [],
          onRender: (item: any) => {
            return <Checkbox styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleDataTypeValueChange(event, item, AffectedDataObj)} disabled={item[AffectedDataObj.AffectedDataTypeId +AffectedDataObj.AffectedDataValue+'IsLocked']} checked={item[AffectedDataObj.AffectedDataTypeId +AffectedDataObj.AffectedDataValue]} />
          }
        });
      }
      }); 
    } 
    return advancedColumns;
  };
  const handleInScopeChange = (event: any, selectedObj: any) => {
    const updatedList = functionalGridList.map((item: any) => {
      if (item.FunctionalScenarioId === selectedObj.FunctionalScenarioId) { 
        item.AffectedData.forEach((affectedData:any) => {
          affectedData.InScope=event.target.checked;
          item[affectedData.AffectedDataTypeId+affectedData.AffectedDataValue]=event.target.checked;//this is only for ui checkbox selection
        });
        return { ...item, InScope: event.target.checked }; // Update the value of the matched item
      }
      return item; // Return unchanged items
    });
    setFunctionalGridList(updatedList); // Update the state with the new array
  }
  const handleDataTypeValueChange = (event: any, selectedObj: any, affectedDataObj: any) => {
    functionalGridList.forEach((functionalGrid:any) => {
      if(functionalGrid.FunctionalScenarioId===selectedObj.FunctionalScenarioId){
        const updatedAffectedData = functionalGrid.AffectedData.map((data: any) => {
                if (data.AffectedDataValue === affectedDataObj.AffectedDataValue) {
                  return { ...data, InScope: event.target.checked };
                }
                return data;
              });
              functionalGrid.AffectedData=updatedAffectedData; 
              functionalGrid[affectedDataObj.AffectedDataTypeId +affectedDataObj.AffectedDataValue]=event.target.checked;
          //if all the checkbox are selected for data values then check checkbox for  inscope for functional scneario level
          functionalGrid.InScope= updatedAffectedData.filter((x:any)=>x.InScope==true).length>=1?true:false; 
      }
    });
    setFunctionalGridList([...functionalGridList]); 
  }
  const onChangeVolumeValueFSGrid = (event: any, selectedObj: any) => {
    const updatedList = functionalGridList.map((item: any) => {
      if (item.FunctionalScenarioId === selectedObj.FunctionalScenarioId) {
        return { ...item, Volume: parseInt(event.target.value) }; // Update the value of the matched item
      }
      return item; // Return unchanged items
    });
   
    setFunctionalGridList(updatedList); // Update the state with the new array 
  };
  //Function Scenario Grid Code End
  //Data Grid Code Start
  const createAffectedDataList = (functionalScenarioGridList:any) => {
    let organized: any = {};
    if (functionalScenarioGridList.length>0) {
      functionalScenarioGridList[0].AffectedData.forEach((data: any) => {
        if(!data.IsDeleted){
          if (!organized[data.AffectedDataTypeName]) {
            organized[data.AffectedDataTypeName] = [];
          }
          organized[data.AffectedDataTypeName].push(data.AffectedDataValue);   
        }
      });
      // Convert organized object into an array of objects
    
      const result = Object.keys(organized).map((key) => ({
        AffectedDataTypeName: key,
        AffectedDataValue: organized[key]
      })); 
     setAffectedDataGridList([...result]); 
    
    }
  }
  const onDeleteAffectedDataValue = (selectedObj: any, selectedDataValue: any) => {

    //disbale delete button when selectedObj.AffectedDataTypeName is OLS Offer ID Request
    if(selectedObj.AffectedDataTypeName === 'OLS Offer ID Request'){
      return;
    }
    functionalGridList.forEach((functionalscenario:any) => { 
      functionalscenario.AffectedData.forEach((element:any) => {
        if(element.AffectedDataValue===selectedDataValue){
            element.IsDeleted=true;
           }          
      })   
     });
     setFunctionalGridList([...functionalGridList]);
     //delete from Data  grid
     affectedDataGridList.forEach((data: any) => {
      if (data.AffectedDataTypeName == selectedObj.AffectedDataTypeName) {
        let removeDeletedData = data.AffectedDataValue.filter((x: any) => x != selectedDataValue);
        data.AffectedDataValue = removeDeletedData;
      }
    });
    setAffectedDataGridList([...affectedDataGridList.filter((x: any) => x.AffectedDataValue.length != 0)])
  }
  const getAffectedDataColumns = (): CoherenceDataGridColumn<any>[] => {
    var advancedColumns: CoherenceDataGridColumn<any>[] = [
      {
        key: 'Data Type',
        name: 'Data Type',
        fieldName: 'AffectedDataTypeName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 200,
        maxWidth: 220,
        ariaLabel: 'Data Type',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.AffectedDataTypeName}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.AffectedDataTypeName}</span>
          </TooltipHost>
        },
        iconClassName: "customSortIcon"
      },
      {
        key: 'Data Value(s)',
        name: 'Data Value(s)',
        fieldName: 'AffectedDataValue',
        type: 'string',
        isResizable: true, 
        isMultiline:true, 
        ariaLabel: 'Data Value(s)',
        data: [],
        onRender: (item: any) => {
          return item.AffectedDataValue.map((adv: any, j: any) => (
            <>
              <label>{adv}</label>
              <IconButton
                onClick={() => onDeleteAffectedDataValue(item, adv)} disabled={item.AffectedDataTypeName === 'OLS Offer ID Request' ? true : false} iconProps={{ iconName: 'Delete', style: { color: "#D43A36" } }}
              />
            </>
          ))
        },
        iconClassName: "customSortIcon"
      }]; 
    return advancedColumns;
  };
  //Data Grid Code End
  //Pattern Data Grid Code Start
   const [patternAffectedDataTypeObj, setPatternAffectedDataTypeObj] = React.useState<any>({ AffectedDataTypeOptions: [], AffectedDataTypeName: "", AffectedDataTypeId: 0, Volume: 0 });

  const OfferIDCreation=(item:any)=>{
   let volume = parseInt(item[0].Volume);   
 
   let offerIds = '';
    

   for (let i = 1; i <= volume; i++) {
     const offerCode = `OFFER-${i.toString().padStart(2, '0')}`;
     offerIds += offerCode;
 
     if (i !== volume) {
       offerIds += ', ';
     }
   }
   functionalGridList.forEach((element: any) => {  
    element.AffectedData =[];
   });

   functionalGridList.forEach((element: any) => {   
   const splitAffectedDataValue = offerIds.split(',');
  
         
    splitAffectedDataValue.forEach((dataValue:any) => { 
    
      let inScope=element.InScope;
       
      if(element.AffectedData.filter((item:any)=>item.AffectedDataValue.trim()===dataValue.trim()).length==0){  
        element.AffectedData.push({
          AffectedDataTypeName: item[0].AffectedDataTypeName,
          AffectedDataTypeId: item[0].AffectedDataTypeId,
          AffectedDataValue: dataValue,
          InScope: inScope,//datavalue specific scope
          UniqueCapabilityCode:element.UniqueCapabilityCode,
          PORId:element.PORId,
          FunctionalScenarioId:element.FunctionalScenarioId,
          IsDeleted:false
        }) 
        element.InScope=element.AffectedData.filter((x:any)=>x.InScope==true).length>0 ? true: inScope;//over all scope
        element[item[0].AffectedDataTypeId+dataValue]=inScope;;//this is temp to show in grid checkbox selection
    }
    else{
        element.AffectedData.forEach((item: any) => { 
          if(item.AffectedDataValue.trim()===dataValue.trim() && inScope){
            item.InScope = inScope;  
            item.IsDeleted = false;    
          }
        });  
        if(inScope){
          element.InScope=element.AffectedData.filter((x:any)=>x.InScope==true).length>0 ? true: inScope;//over all scope        
          element[item[0].AffectedDataTypeId+dataValue]=inScope;;//this is temp to show in grid checkbox selection   
        }
    }  
   
    });
     
    });

    setFunctionalGridList(functionalGridList);
    setAffectedDataObj({ ...affectedDataObj, AffectedDataTypeId:0,AffectedDataTypeName:"",AffectedDataValue:"",FunctionalScenarioIds:[]});
    createAffectedDataList(functionalGridList);//this will create the list of affected  data values group by affected data types
  }
  //Pattern Data Grid Code End
  const generateAddPatternButton = (): any => {
    const _items: ICommandBarItemProps[] = [
      {
        key: 'Add Pattern',
        text: 'Add Pattern',
        iconProps: { iconName: 'Add' },
        disabled: isDisabledSaveButton || isPORLaunchedState,
        onClick: () => {
          setIsPatternChangedInEditMode(false);
          setBooleanAffectedDataValueErrorMessage(false); 
          setShowAffectedDataValueErrorMessage(false);
          setIsEditPattern(false);
          seteditColumnsDefaultValues({ "CapabilityId": 0, "SubCategoryId": 0, "CatalogCategoryId": 0, "PatternId": 0, "AffectedDataTypes": "", "AffectedDataValues": "" });
          setshowEditGridPanel(true);
          setAffectedDataGridList([]);
          setFunctionalGridList([]); 
          setSubCategoryList([]);
          setPatternList([]);
          setCapabilityList([]);
          setAffectedDataList([]);
          setFunctionalScenarioList([]);
          setBulkEditSelectedData([]);
          setAffectedDataObj({ ...affectedDataObj,AffectedDataTypeId: "", AffectedDataTypeName: "", AffectedDataValue: "", FunctionalScenarioIds: [] });
          setPORDetails({ ...porDetails,Capability: 0, Category: 0, SubCategory: 0, Pattern: 0, UniqueCapabilityCode: 0 });
          let serviceLineId = serviceLineList.filter((x: any) => x.text === porDetails.ServiceLine)[0].key;
          LoadPatternsbyServiceLine(serviceLineId);
        }
      },
      {
        key: 'ActionsDelete',
        name: 'Delete Pattern',
        type: 'actions',
        iconProps: {
          iconName: 'Delete',
          style: { color: "red" }
        },
        isResizable: false,
        menuActions: [],
        minColumnWidth: 55,
        maxWidth: 55,
        data: [],
        
        onClick: () => {
            const result = window.confirm('Are you sure you want to delete the selected pattern(s)?');
    
            if (result) {

              DeletePattern(scopeDefinitionPattern);
           
            } 
          
        },
         disabled: bulkEditSelectedData.length > 0 && !isDisabledSaveButton && !isPORLaunchedState ? false : true,
      }
    
    ];
    return _items;
  }
  // affected data code start
  const [affectedDataObj, setAffectedDataObj] = useState<any>({ "AffectedDataTypeId": "", "AffectedDataTypeName": "", "AffectedDataValue": "", "FunctionalScenarioIds": [] })
  const onChangeAffectedDataValue = (event: any) => {
    IsDataValid(affectedDataObj.AffectedDataTypeId,event.target.value.toUpperCase());
    setAffectedDataObj({ ...affectedDataObj, AffectedDataValue: event.target.value.toUpperCase() });   
  } 
  const onChangeAffectedData = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
    IsDataValid(item.key,affectedDataObj.AffectedDataValue);
    setAffectedDataObj({ ...affectedDataObj, AffectedDataTypeId: item.key, AffectedDataTypeName: item.text });
  }
  function onChangeMultiselectFunctionScenario(event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) {
    if (!option) return;
    
    let selectedValues = [...affectedDataObj.FunctionalScenarioIds];
    if (option.selected) {
      selectedValues.push(option.key);
    }
    else {
      selectedValues = selectedValues.filter((item: any) => item !== option.key);
    }
    setAffectedDataObj({ ...affectedDataObj, "FunctionalScenarioIds": selectedValues });
  }
   
    const [transformedData, setTransformedData] = useState<any>([]);
    function transformData(data: any) {
    
    data.forEach((item:any) => {
      if (!transformedData[item.AffectedDataTypeName]) {
            transformedData[item.AffectedDataTypeName] = [];
          }
          transformedData[item.AffectedDataTypeName].push(item.AffectedDataValue);
    });
   
    const result = Object.keys(transformedData).map(id => ({
      AffectedDataTypeName: id,
      
      AffectedDataValue: transformedData[id],
    }));
   
    setTransformedData([]);
    return result;
    
  }
  const [showAffectedDataValueErrorMessage, setShowAffectedDataValueErrorMessage] = useState<any>();
  const [booleanAffectedDataValueErrorMessage, setBooleanAffectedDataValueErrorMessage] = useState(false);
 
  const IsDataValid =(AffectedDataTypeId : any,AffectedDataValue:any) => {
    let isValid=true;
    setBooleanAffectedDataValueErrorMessage(false);
      if(!isEmpty(AffectedDataTypeId) && !isEmpty(AffectedDataValue)){
        const splitAffectedDataValue = AffectedDataValue
        .split(',')
        .map((item:any) => item.trim());
        // PFAM Validation
        if(AffectedDataTypeId===1){
          let inValidPfamsCode:string;
          let regex = /^[0-9a-zA-Z]+$/;
          splitAffectedDataValue.forEach((dataValue:any) => {
                  
          if (isEmpty(dataValue) || !regex.test(dataValue) || (dataValue.length!=3 && dataValue.length!=5)) {
                
            if(isEmpty(inValidPfamsCode)){
              inValidPfamsCode=dataValue;
              
            }
            else{
              inValidPfamsCode=inValidPfamsCode+","+dataValue;
            }        
            setBooleanAffectedDataValueErrorMessage(true);
                  
            setShowAffectedDataValueErrorMessage("PFAM should be either 3 characters or 5 characters: " + inValidPfamsCode);
          
          isValid=false;
          }
          
        });
      }
        // SKU Validation
        if(AffectedDataTypeId===2){
          let InvalidSKus:string;
          let regex = /^[0-9a-zA-Z]{3}-[0-9a-zA-Z]{5}$/;
          splitAffectedDataValue.forEach((dataValue:any) => {
              
              if (isEmpty(dataValue) || !regex.test(dataValue) || (dataValue.length!=9)) {
                if(isEmpty(InvalidSKus)){
                  InvalidSKus=dataValue;
                }
                else{
                  InvalidSKus=InvalidSKus+", "+dataValue;
                }        
                setBooleanAffectedDataValueErrorMessage(true);
                setShowAffectedDataValueErrorMessage("SKU should be 9 characters (Ex: 9EA-01073): " + InvalidSKus);
              isValid=false;
              }
          })
        }  
      }
    return isValid;
  }

    useEffect(() => {
        IsDataValid(affectedDataObj.AffectedDataTypeId, affectedDataObj.AffectedDataValue);
    }, affectedDataObj);

  const onAddAffectedDataTypes = (): any => {
   if(!IsDataValid(affectedDataObj.AffectedDataTypeId, affectedDataObj.AffectedDataValue)){
      return false;
    }  

    
     if (!isEmpty(affectedDataObj.AffectedDataTypeId) && !isEmpty(affectedDataObj.AffectedDataValue)) {      
     functionalGridList.forEach((element: any) => {   
      const splitAffectedDataValue = affectedDataObj.AffectedDataValue
      .split(',')
      .map((item:any) => item.trim());

      splitAffectedDataValue.forEach((dataValue:any) => { 
          let inScope = affectedDataObj.FunctionalScenarioIds.filter((x: any) => x === element.FunctionalScenarioId).length > 0 ? true : false
        if(element.AffectedData.filter((item:any)=>item.AffectedDataValue.trim()===dataValue.trim()).length==0){  
          element.AffectedData.push({
            AffectedDataTypeName: affectedDataObj.AffectedDataTypeName,
            AffectedDataTypeId: affectedDataObj.AffectedDataTypeId,
            AffectedDataValue: dataValue,
            InScope: inScope,//datavalue specific scope
            UniqueCapabilityCode:element.UniqueCapabilityCode,
            PORId:element.PORId,
            FunctionalScenarioId:element.FunctionalScenarioId,
            IsDeleted:false
          }) 
          element.InScope=element.AffectedData.filter((x:any)=>x.InScope==true).length>0 ? true: inScope;//over all scope
          element[affectedDataObj.AffectedDataTypeId+dataValue]=inScope;;//this is temp to show in grid checkbox selection
      }
      else{
          element.AffectedData.forEach((item: any) => { 
            if(item.AffectedDataValue.trim()===dataValue.trim() && inScope){
              item.InScope = inScope;  
              item.IsDeleted = false;    
            }
          });  
          if(inScope){
            element.InScope=element.AffectedData.filter((x:any)=>x.InScope==true).length>0 ? true: inScope;//over all scope
            element[affectedDataObj.AffectedDataTypeId+dataValue]=inScope;;//this is temp to show in grid checkbox selection   
          }
      }  
     
      });
       
      });
  
      setFunctionalGridList(functionalGridList);
      setAffectedDataObj({ ...affectedDataObj, AffectedDataTypeId:0,AffectedDataTypeName:"",AffectedDataValue:"",FunctionalScenarioIds:[]});
      createAffectedDataList(functionalGridList);//this will create the list of affected  data values group by affected data types      
    } 
  }
  
  
  const [isPatternChangedInEditMode, setIsPatternChangedInEditMode] = useState<boolean>(false);
  const showConfirmationBeforeChangingPattern = (): any => {
    const isConfirmed = window.confirm('Changing this value will erase all the previously saved data?');    
    if(isConfirmed)  { 
      let setPatternChangedInEditMode=true;
      setIsPatternChangedInEditMode(setPatternChangedInEditMode);
      setAffectedDataGridList([]);
      setFunctionalGridList([]);                    
     } 
   return isConfirmed;
  }
  //affected data code end
const isSaveButtonDisabled = ():any=>{
  if(isEditPattern){    
    let getModifiedData = functionalGridList.filter((item:any) => {
      // Find if the current item exists in functionalGridListRef using the custom comparison function
      return !functionalGridListRef.some((refItem:any) =>
      deepEqual(refItem, item)
      );
    });  
    return (getModifiedData.length>0) ?false:true;
  }
  else{   
   //if any one of the functionalGridList.InScope is true then only enable save button for Agreements and Contents
    return (((porDetails.Category==3 ||porDetails.Category==5)  && functionalGridList.length>0 && functionalGridList.filter((x:any)=>x.InScope==true).length>0)||(functionalGridList.length>0 && affectedDataGridList.length>0)) ?false:true; 
  } 
}
  const generatePORDetailControls = (): any => {
    let tmpRenderObj: any[] = [];
    for (let editRowColumn of editRowColumns) {
      switch (editRowColumn.type) {
        case 'DropDown':
          {
            if (editRowColumn.label === 'CatalogCategory' && porDetails.Category!=-1) {
              tmpRenderObj.push(
                <Dropdown
                  key={editRowColumn.key}
                  label={'Category'}
                  options={catalogCategoryList}
                  placeholder={"Please select the Category"}
                  defaultSelectedKey={porDetails.Category}
                  defaultValue={porDetails.Category}
                  onChange={(
                    event: React.FormEvent<HTMLDivElement>,
                    option?: IDropdownOption<any> | undefined,
                    index?: number | undefined
                  ) => {
                    if(isEditPattern && !isPatternChangedInEditMode){ 
                      let isConfirmed=showConfirmationBeforeChangingPattern();    
                      if(isConfirmed)  {                       
                        onChangeCategory(event, option, index);                    
                       }
                       else{ 
                        setPORDetails({ ...porDetails, Category: -1 });   
                        setTimeout(() => {
                          setPORDetails({ ...porDetails, Category: JSON.parse(JSON.stringify(porDetailsRef.Category)) });                           
                        }, 100); 
                       }
                      }  
                      else{ 
                        onChangeCategory(event, option, index);
                      } 
                  }
                  }
                />
              )
            }
            else if (editRowColumn.label === 'Subcategory' && !isEmpty(subCategoryList) && !(subCategoryList.length == 1 && subCategoryList.filter((x: any) => x.text === "n/a").length > 0) && porDetails.SubCategory!=-1) {
              tmpRenderObj.push(
                <Dropdown key={editRowColumn.key}
                  label={editRowColumn.label}
                  options={subCategoryList ?? []}
                  defaultSelectedKey={porDetails.SubCategory}
                  onChange={(ev, selected) => {      
                    if(isEditPattern && !isPatternChangedInEditMode){ 
                      let isConfirmed=showConfirmationBeforeChangingPattern();    
                        if(isConfirmed)  {
                        setAffectedDataGridList([]);
                        setFunctionalGridList([]);                        
                        onChangeSubCategory(ev, selected, editRowColumn);           
                       }
                       else{ 
                        setPORDetails({ ...porDetails, SubCategory: -1 });   
                        setTimeout(() => {
                          setPORDetails({ ...porDetails, SubCategory: JSON.parse(JSON.stringify(porDetailsRef.SubCategory)) });                           
                        }, 100); 
                       }
                      }  
                      else{ 
                        onChangeSubCategory(ev, selected, editRowColumn);
                      } 
                  }} 
                  placeholder={"Select " + editRowColumn.label}
                  required={true}
                  defaultValue={porDetails.SubCategory}
                />
              );
            }
            else if (editRowColumn.label === 'Pattern' && porDetails.Pattern!=-1) {
              tmpRenderObj.push(
                <Dropdown
                  key={editRowColumn.key}
                  label={'Pattern'}
                  options={patternsList}
                  placeholder={"Please select the pattern"}
                  onChange={(ev, selected) => { 
                    if(isEditPattern && !isPatternChangedInEditMode){ 
                      let isConfirmed=showConfirmationBeforeChangingPattern();    
                        if(isConfirmed)  {
                        setAffectedDataGridList([]);
                        setFunctionalGridList([]);                        
                        onChangePattern(ev, selected);                    
                       }
                       else{ 
                        setPORDetails({ ...porDetails, Pattern: -1 });   
                        setTimeout(() => {
                          setPORDetails({ ...porDetails, Pattern: JSON.parse(JSON.stringify(porDetailsRef.Pattern)) });                           
                        }, 100); 
                       }
                      }  
                      else{ 
                        onChangePattern(ev, selected);
                      } 
                  }}
                  
                  defaultSelectedKey={porDetails.Pattern}
                  defaultValue={porDetails.Pattern}
                />
              )
            }
            else if (editRowColumn.label === 'Capability' && porDetails.Capability!=-1) {
              tmpRenderObj.push(
                <Dropdown
                  key={editRowColumn.key}
                  label={'Capability'}
                  options={capabilityList}
                  placeholder={"Please select the capability"}
                  onChange={(ev, selected) => { 
                    if(isEditPattern && !isPatternChangedInEditMode){ 
                      let isConfirmed=showConfirmationBeforeChangingPattern();    
                        if(isConfirmed)  {
                        setAffectedDataGridList([]);
                        setFunctionalGridList([]);                        
                        onChangeCapability(ev, selected);                    
                       }
                       else{ 
                        setPORDetails({ ...porDetails, Capability: -1 });   
                        setTimeout(() => {
                          setPORDetails({ ...porDetails, Capability: JSON.parse(JSON.stringify(porDetailsRef.Capability)) });                           
                        }, 100); 
                       }
                      }  
                      else{ 
                        onChangeCapability(ev, selected);
                      }
                     
                  }}
                  defaultSelectedKey={porDetails.Capability}
                  defaultValue={porDetails.Capability}
                />
              )
            }
            break;
          }
        default:
      }   
    } 
    return <div>
      <div style={{ display: "grid", gridTemplateColumns: "50% 50%", gridGap: "1rem", paddingBottom: 20 }}> 
          {tmpRenderObj} 
      </div>
      <div>
        <Accordion title="Add Data" isExpandDefault={true}> 
            <div style={{ display: "grid", gridTemplateColumns: "48% 48%", gridGap: "1rem" }}>
              <Dropdown
                key={affectedDataList.key}
                label={'Data Type'}
                options={affectedDataList}
                placeholder={"Select Data Type"}
                required={true}
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption<any> | undefined,
                  index?: number | undefined
                ) => {
                  onChangeAffectedData(event, option, index);
                }
                }
                selectedKey={affectedDataObj.AffectedDataTypeId}
              />
              <TextField
                label="Data Value(s)"
                placeholder={"Enter Data Value(s)"}
                required = {true}
                errorMessage={booleanAffectedDataValueErrorMessage ? showAffectedDataValueErrorMessage : undefined}
                value={affectedDataObj.AffectedDataValue}
                onChange={onChangeAffectedDataValue}
                maxLength={1000}
              />
              <Dropdown
                label={"Functional Scenario(s)"}
                options={functionalScenarioList}
                placeholder={"Select Functional Scenario(s)"}
                required={false}
                multiSelect
                selectedKeys={affectedDataObj.FunctionalScenarioIds}
                onChange={(ev, selected) => onChangeMultiselectFunctionScenario(ev, selected)}
              />
              {/* , alignItems: "center"  */}
              <div style={{ display: "flex"}}>
                <PrimaryButton
                  text="Add"
                  disabled={
                 ( isEmpty(affectedDataObj.AffectedDataTypeId) ||
                   isEmpty(affectedDataObj.AffectedDataValue) || booleanAffectedDataValueErrorMessage )
                      ? true
                      : false
                  }
                  onClick={onAddAffectedDataTypes}
                  style={{ flex: "0 0 auto", marginTop: "29px" }}
                />
              </div>
            </div> 
          <div style={{ minHeight: 300, height: 300, maxHeight: 300 }}>
          {!isFunctionalScenarioGridLoading ?  <CoherenceDataGrid
              listConfig={getAffectedDataColumns()}
              data={affectedDataGridList}
              isScrollable={true}
              isSortable={false}
              sortByDefault={false}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              ariaLabel={"Data Types List"}
            ></CoherenceDataGrid>
            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
          </div>
        </Accordion>
        <Accordion title="Functional Scenarios" isExpandDefault={true}>
          <div style={{ minHeight: 320, height: 320, maxHeight: 320 }}>
            {!isFunctionalScenarioGridLoading ? <CoherenceDataGrid
              listConfig={getFunctionalScenarioColumns()}
              data={functionalGridList}
              isScrollable={true}
              isSortable={false}
              sortByDefault={false}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              ariaLabel={"Functional Scenario  List"}
            ></CoherenceDataGrid>
              : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
          </div>
        </Accordion>
      </div>
    </div>
  }
  return <div>
    { !isPageLoaded && 
     <div style={{ 
  minHeight: '100vh', // Set the minimum height to 100% of the viewport height
  height: '100vh',    // Set the height to 100% of the viewport height
  maxHeight: '100vh', // Set the maximum height to 100% of the viewport height
  minWidth: '100vw',  // Set the minimum width to 100% of the viewport width
  width: '100vw',     // Set the width to 100% of the viewport width
  maxWidth: '100vw'   // Set the maximum width to 100% of the viewport width
}}>
    
<CoherenceLoading primaryText={""}></CoherenceLoading>
</div>
}
{isPageLoaded && !isAuthorized  &&
  <div style={{
    minHeight: '100vh' //this added to adjust footer to screen height
  }}>
  <h2 className={unauthorizedHeader}>Access Denied</h2>
  <p>This is a confidential POR and only Contacts listed on the POR are authorized to access.
   If you believe you should be able to access the POR, Please reach out to  <strong>{porDetails.PrimaryContact[0].text.toString()}</strong> to get access.
     </p>

</div>
}

{isAuthorized && <div style={{display: isPageLoaded ?    'block' :'none' }}>
     {isPageLoaded && showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
    <Breadcrumb
      ariaLabel="Breadcrumb bar with 2 items"
      items={[
        {
          href: '/PORItems',
          key: 'PORItems',
          text: 'Plan of Record',
          style: {
            color: '#0072c9'
          }
        },
        {
          isCurrentItem: true,
          key: isEditMode ? 'Scope Definition' : 'Create POR',
          text: isEditMode ? 'Scope Definition' : 'Create POR'
        }
      ]}
      overflowAriaLabel="More links"
    />
    <Accordion title="Scope Summary" isExpandDefault={true}>
      <CommandBar
        items={[]}
        farItems={generateAddPatternButton()}
        ariaLabel="Add Pattern"
        primaryGroupAriaLabel="Add Pattern"
        farItemsGroupAriaLabel="Add Pattern"
      />
      <div style={{ minHeight: 300, height: 300, maxHeight: 425 }}>
        {isPageLoaded && !isLoaded  ? <CoherenceDataGrid
          listConfig={getColumns()}
          data={scopeSummaryList}
          isScrollable={true}
          isSortable={false}
          sortByDefault={false}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          ariaLabel={"Scope Summary List"}
        ></CoherenceDataGrid>
          : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
      </div>
    </Accordion>
    <CoherencePanel       
      titleText={"Pattern Details"}
      isOpen={showEditGridPanel}      
      onDismiss={validateChangesBeforeClose}
      panelSize={CoherencePanelSize.large}
      closeButtonAriaLabel="Close" 
      onRenderFooter={() => (
        <Stack horizontal >
          <PrimaryButton text="Save" disabled={isSaveButtonDisabled()  || isDisabledSaveButton || isLoaded} onClick={onSavePatternClick} />
          <DefaultButton text="Cancel" onClick={validateChangesBeforeClose} disabled={isLoaded} />
        </Stack>
      )}
    >
      {showEditGridPanel && generatePORDetailControls()}
    </CoherencePanel>
 
    { isExpandAccordion ? 
            <div>
            <Accordion title="Catalog Alignment" isExpandDefault={isExpandAccordion}>
              <ManagedCatalogAlignment reloadSection={reloadRD}></ManagedCatalogAlignment>  
            </Accordion>
           { !reloadSection ?
            <Accordion title="Requirement Definition" isExpandDefault={isExpandAccordion}>
              <ManagedRequirementDefinition  ></ManagedRequirementDefinition>  
            </Accordion>
            :null}
            </div>
        :   null 
      }
  </div>
}
</div>
};
export default ScopeDefinition;
